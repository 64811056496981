import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadShipments = async (shell, associateId, startDate, endDate, offset, limit, sort, hideCustomerOwnerShipments = null, associateFilter = null) => {

    const {shipments, count} = await shell.gateway.getAssociateMonthlyGmShipments(
        {
            associateId,
            startDate,
            endDate,
            offset,
            limit,
            sort
        },
        hideCustomerOwnerShipments,
        associateFilter?.agencyCustomerOwner?.id
    );

    return {
        type: ActionTypes.Reporting.MonthlyGrossMargin.ShipmentsLoaded,
        shipments: shipments,
        shipmentCount: count
    }

};

export default ActionBuilder
    .for(loadShipments)
    .build();
