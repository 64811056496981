import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import AppMuiButton from "../../../../../core/components/AppButton";
import {Save, Undo} from "@material-ui/icons";
import TgfAgencyAssociatesComboBox from "../../../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import TgfMultiList from "../../../../../core/kendo/multiple-list/TgfMultiList";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";

const EditAssignmentModal = (props) => {

    const {
        onClose,
        customerId,
        customerName
    } = props;

    const companyId = parseInt(customerId);
    const personalDispatch = useDispatch();
    const ref = useRef();

    const [isReady, setIsReady] = useState(false);
    const [company, setCompany] = useState(null);
    const [agencyId, setAgencyId] = useState([]);

    const [availableAssociates, setAvailableAssociates] = useState([]);
    const [involvedAssociates, setInvolvedAssociates] = useState([]);

    const [initialAvailableAssociates, setInitialAvailableAssociates] = useState([]);
    const [initialInvolvedAssociates, setInitialInvolvedAssociates] = useState([]);



    const [selectedAgencyAssociate, setSelectedAgencyAssociate] = useState();
    const [initialSelectedAgencyAssociate, setInitialSelectedAgencyAssociate] = useState();

    const [isDirty, setIsDirty] = useState(false);
    const [assignmentListIsDirty, setAssignmentListIsDirty] = useState(false);
    const [ownerAssignmentIsDirty, setOwnerAssignmentIsDirty] = useState(false);

    const checkIfDirty = () => {
        if (isReady) {
            setIsDirty(false);
            setAssignmentListIsDirty(false);
            setOwnerAssignmentIsDirty(false)

            if (selectedAgencyAssociate?.id !== initialSelectedAgencyAssociate?.id) {
                setIsDirty(true);
                setOwnerAssignmentIsDirty(true);
            }

            if (!(involvedAssociates.every(item => initialInvolvedAssociates.includes(item)) && initialInvolvedAssociates.every(item => involvedAssociates.includes(item)))) {
                setIsDirty(true);
                setAssignmentListIsDirty(true);
            }
        }
    };


    const handleSelectionOfAgencyAssociate = (e) => {
        setSelectedAgencyAssociate(e.value);
    };

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAssociate'));

            const company = await window.shell.gateway.getCompany(companyId);
            setCompany(company);
            setAgencyId(company.associateId);

            const agencyAssociates = (await window.shell.gateway.getPageOfAgencyAssociates({
                filter: {
                    agencyId: company.associateId,
                    accessFilterId: 2,
                },
            })).items;

            const agencyInvolvedAssociates = await window.shell.gateway.retrieveInvolvedUsers(companyId);
            const involvedAssociates = [];

            agencyInvolvedAssociates.forEach((item) => {
                const user = agencyAssociates.filter((obj) => obj.id === item.associateId)[0];
                const index = agencyAssociates.indexOf(user);
                let actual = agencyAssociates[index];
                if (actual == null) return;
                actual.fullName = `${actual.firstName} ${actual.lastName}${!actual.isActive ? " (inactive)" : ""}`;
                involvedAssociates.push(actual);
                agencyAssociates.splice(index, 1);
            });

            const customerOwner = agencyInvolvedAssociates.find((associate) => associate.isOwner);
            if (customerOwner) {
                const selectedAgencyAssociate = involvedAssociates.find((associate) => associate.id === customerOwner.associateId);
                setSelectedAgencyAssociate(selectedAgencyAssociate);
                setInitialSelectedAgencyAssociate(selectedAgencyAssociate);
            } else {
                setSelectedAgencyAssociate(null);
                setInitialSelectedAgencyAssociate(null);
            }

            setInvolvedAssociates(involvedAssociates);
            setInitialInvolvedAssociates(involvedAssociates);

            setAvailableAssociates(agencyAssociates);
            setInitialAvailableAssociates(agencyAssociates);

            ref.current?.reset(involvedAssociates, agencyAssociates);

            setIsReady(true);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAssociate'));
        }
    };

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAgencyAssociate'));

            await window.shell.gateway.setInvolvedUsers(companyId, agencyId, selectedAgencyAssociate?.id, involvedAssociates);

            await loadPageDependencies();
            props.onClose();
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save User Assignments',
                color: 'error',
            }));
        } finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'User Assignments Saved',
            }));
            personalDispatch(window.shell.actions.sys.processComplete('saveAgencyAssociate'));
        }
    };

    const handleResetClick = () => {
        setSelectedAgencyAssociate(initialSelectedAgencyAssociate);
        setAvailableAssociates(initialAvailableAssociates);
        setInvolvedAssociates(initialInvolvedAssociates);
        ref.current.reset(initialInvolvedAssociates, initialAvailableAssociates);
        setIsDirty(false);
    };

    const handleListChange = (value) => {
        setAvailableAssociates(value.right);
        setInvolvedAssociates(value.left);

        value.right.forEach((item) => {
            if (item.id === selectedAgencyAssociate?.id) {
                setSelectedAgencyAssociate(null);
            }
        });
    };

    useEffect(() => {
        loadPageDependencies();
    }, []);

    useEffect(() => {
        checkIfDirty();
    }, [selectedAgencyAssociate, involvedAssociates]);

    const associateDisplayFunction = (associate) => {
        const isCustomerOwner = selectedAgencyAssociate?.id === associate?.id;

        return (
            <span style={{color: !associate.isActive ? "red" : "inherit"}}>
                {associate.firstName} {associate.lastName}{!associate.isActive ? " (inactive)" : ""}
                {isCustomerOwner ? <span style={{fontWeight: "bold", color: "blue"}}> (customer owner)</span> : ""}
            </span>
        );
    };

    return (
        <TgfDialog
            title={`Edit Assingment - ${customerName}`}
            onClose={onClose}
            width={1000}
        >
            {company?.name &&
                    <Grid container spacing={3}>
                        {/* Buttons */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <AppMuiButton
                                        type={'submit'}
                                        startIcon={<Save />}
                                        disabled={!isDirty}
                                        onClick={save}
                                    >
                                        Save
                                    </AppMuiButton>
                                </Grid>
                                <Grid item>
                                    <AppMuiButton
                                        type={'button'}
                                        startIcon={<Undo />}
                                        color={'warning'}
                                        disabled={!isDirty}
                                        onClick={handleResetClick}
                                    >
                                        Reset
                                    </AppMuiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} md={3}>
                                {isReady &&
                                    <>
                                        <Typography variant="h6">Customer Owner:</Typography>
                                        <TgfAgencyAssociatesComboBox
                                            onChange={handleSelectionOfAgencyAssociate}
                                            selectedAgencyAssociate={selectedAgencyAssociate}
                                            activeOnly={false}
                                            visibilityPermissionIdFilter={2}
                                            actionRoleId={3}
                                            agencyId={agencyId}
                                            disabled={Boolean(assignmentListIsDirty)}
                                        />
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {isReady && <TgfMultiList
                                ref={ref}
                                right={availableAssociates}
                                left={involvedAssociates}
                                rightLabel={'Available Users to Assign...'}
                                leftLabel={'Assigned Users...'}
                                onChange={handleListChange}
                                idFunction={(item) => `${item.firstName} ${item.lastName}`}
                                displayFunction={associateDisplayFunction}
                                disabled={Boolean(ownerAssignmentIsDirty)}
                                setDirtyWhenToggled={setAssignmentListIsDirty}
                            />}
                        </Grid>
                    </Grid>
            }
        </TgfDialog>
    );
};

export default EditAssignmentModal;
