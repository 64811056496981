import {Grid} from '@material-ui/core';
import FullWidthLayout from 'core/layouts/FullWidthLayout';
import React, {useEffect, useRef, useState} from 'react';
import {usePageTitle} from "crm/components/customHooks/misc/usePageTitle";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import isHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import LeftNav from '../LeftNav';
import TgfAgencyComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import {AgencyCustomersGrid} from "./includes/AgencyCustomersGrid/AgencyCustomerGrid";
import validateCanViewAgencyPage from "../../../hubs/personaV2/selectors/canViewAgencyPage";
import {UserCustomerVisibilityModal} from "./includes/UserCustomerVisibilityModal/UserCustomerVisibilityModal";
import EditAssignmentModal from "./includes/EditAssignmentModal/EditAssignmentModal";
import TgfAgencyAssociatesComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import useEmployeeContext from "../../../hubs/personaV2/selectors/getEmployeeContext";
import {Checkbox} from "@progress/kendo-react-inputs";

export const CustomerAssignment = () => {
    const isAdmin = useIsAdministrator();
    const gridRef = useRef();
    usePageTitle("TGF: Customer Assignment");
    const headOfAgency = isHeadOfAgency();
    const employeeContext = useEmployeeContext();
    const canViewAgencyPage = validateCanViewAgencyPage();

    const [selectedAgencyId, setSelectedAgencyId] = useState(headOfAgency ? employeeContext?.agency?.id : null);
    const [associateFilter, setAssociateFilter] = useState(null);

    const [userCustomerVisibilityModalOpen, setUserCustomerVisibilityModalOpen] = useState(false);
    const [userCustomerVisibilityModalProps, setUserCustomerVisibilityModalProps] = useState({});

    const [editAssignmentModalOpen, setEditAssignmentModalOpen] = useState(false);
    const [editAssignementModalprops, setEditAssignmentModalProps] = useState({});

    const [showOnlyActiveCustomers, setShowOnlyActiveCustomers] = useState(false);
    const [showOnlyInactiveCustomers, setShowOnlyInactiveCustomers] = useState(false);

    const handleCloseAssignmentModal = () => {
        setEditAssignmentModalOpen(false);
        reload();
    }


    const handleSelectAgency = (e) => {
        setAssociateFilter(null);
        setShowOnlyActiveCustomers(false);
        setShowOnlyInactiveCustomers(false);
        setSelectedAgencyId(e.target.value);
    };

    const onSelectAssociate = (e, type) => {
        const associate = e?.target?.value ? e?.target?.value : e;
        if (!associate?.id) {
            setAssociateFilter(null);
            return
        }
        switch (type) {
            case "agency customer owner":
                setAssociateFilter({agencyCustomerOwner: associate})
                break;
            case "agency assigned user":
                setAssociateFilter({agencyAssignedUser: associate})
                break;
            case "agency any":
                setAssociateFilter({agencyAny: associate})
                break;
            default:
                setAssociateFilter(null);
        }
    };

    const handleHasVisibilityColumnClick = (customerId, customerName) => {
        setUserCustomerVisibilityModalProps({customerId, customerName});
        setUserCustomerVisibilityModalOpen(true);
    }

    const handleEditAssignmentClick = (customerId, customerName) => {
        setEditAssignmentModalProps({customerId, customerName});
        setEditAssignmentModalOpen(true);
    }

    const handleShowOnlyActiveCustomers = (e) => {
        setShowOnlyInactiveCustomers(false);
        setShowOnlyActiveCustomers(e.target.value);
    }

    const handleShowOnlyInactiveCustomers = (e) => {
        setShowOnlyActiveCustomers(false);
        setShowOnlyInactiveCustomers(e.target.value);
    }

    const reload = () => {
        gridRef.current?.refresh();
    };

    useEffect(() => {
        reload();
    }, [selectedAgencyId, headOfAgency, showOnlyActiveCustomers, showOnlyInactiveCustomers]);

    useEffect(() => {
        if(selectedAgencyId){
            gridRef.current?.setPageIndexAndRefresh(0);
        }
    }, [associateFilter]);

    return (
        canViewAgencyPage ? <FullWidthLayout SideNav={LeftNav} title="Customer Assignment">
            <Grid container spacing={2}>
                {isAdmin &&
                    <Grid item xs={12} md={3}>
                        <TgfAgencyComboBox
                            label={"Franchise Mode Agencies"}
                            onChange={handleSelectAgency}
                            value={selectedAgencyId}
                            franchiseMode={true}
                            isDisabled={false}
                        />
                    </Grid>
                }
                {isAdmin && <Grid item xs={9}></Grid> }
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={'Filter by customer owners...'}
                        onChange={(e) => onSelectAssociate(e, "agency customer owner")}
                        selectedAgencyAssociate={associateFilter?.agencyCustomerOwner ?? null}
                        activeOnly={true}
                        visibilityPermissionIdFilter={2}
                        actionRoleId={3}
                        customerOwnersOnly={true}
                        includeUnassignedOption={true}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAny || associateFilter?.agencyAssignedUser || !selectedAgencyId)}
                    />
                </Grid>
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={'Filter by assigned users...'}
                        onChange={(e) => onSelectAssociate(e, "agency assigned user")}
                        selectedAgencyAssociate={associateFilter?.agencyAssignedUser ?? null}
                        activeOnly={true}
                        visibilityPermissionIdFilter={2}
                        actionRoleId={3}
                        customerOwnersOnly={false}
                        includeNoneOption={true}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAny || associateFilter?.agencyCustomerOwner || !selectedAgencyId)}
                    />
                </Grid>
                <Grid item xs={2} style={{display: "flex"}}>
                    <TgfAgencyAssociatesComboBox
                        placeholder={'Filter by user visibility...'}
                        onChange={(e) => onSelectAssociate(e, "agency any")}
                        selectedAgencyAssociate={associateFilter?.agencyAny ?? null}
                        activeOnly={true}
                        visibilityPermissionIdFilter={null}
                        actionRoleId={null}
                        customerOwnersOnly={false}
                        agencyId={selectedAgencyId}
                        disabled={Boolean(associateFilter?.agencyAssignedUser || associateFilter?.agencyCustomerOwner || !selectedAgencyId)}
                    />
                </Grid>
                <Grid item>
                    <Checkbox
                        value={showOnlyActiveCustomers}
                        id={"showOnlyActiveCustomers"}
                        name={"showOnlyActiveCustomers"}
                        label={"Hide All Disabled Customers"}
                        onChange={handleShowOnlyActiveCustomers}
                        disabled={!selectedAgencyId}
                    />
                </Grid>
                <Grid item>
                    <Checkbox
                        value={showOnlyInactiveCustomers}
                        id={"showOnlyInactiveCustomers"}
                        name={"showOnlyInactiveCustomers"}
                        label={"Show Only Disabled Customers"}
                        onChange={handleShowOnlyInactiveCustomers}
                        disabled={!selectedAgencyId}
                    />
                </Grid>
                    <Grid item xs={12}>
                        <AgencyCustomersGrid
                            ref={gridRef}
                            handleHasVisibilityClick={handleHasVisibilityColumnClick}
                            handleEditAssignmentClick={handleEditAssignmentClick}
                            selectedAgencyId={selectedAgencyId}
                            associateFilter={associateFilter}
                            customerAssociateId={selectedAgencyId}
                            showOnlyActiveCustomers={showOnlyActiveCustomers}
                            showOnlyInactiveCustomers={showOnlyInactiveCustomers}
                        />
                    </Grid>
                {userCustomerVisibilityModalOpen &&
                    <Grid item xs={12}>
                        <UserCustomerVisibilityModal
                            {...userCustomerVisibilityModalProps}
                            onClose={() => setUserCustomerVisibilityModalOpen(false)}
                        />
                    </Grid>
                }
                {editAssignmentModalOpen &&
                    <Grid item xs={12}>
                        <EditAssignmentModal
                            {...editAssignementModalprops}
                            onClose={handleCloseAssignmentModal}
                        />
                    </Grid>
                }
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    );
};
