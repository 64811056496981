import {Grid} from '@material-ui/core';
import React, {forwardRef} from "react";
import {useDispatch} from "react-redux";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {
    AssignedOwnerCell,
    AssignedUsersCell,
    CompanyLinkCell,
    EditAssignmentCell,
    HasVisibilityCell,
    IsActiveCell,
    LastNoteCell
} from "./agencyCustomerGridCells";


export const AgencyCustomersGrid = forwardRef(function AgencyUsersGrid(props, ref) {
    const {
        handleHasVisibilityClick,
        handleEditAssignmentClick,
        associateFilter,
        customerAssociateId,
        showOnlyActiveCustomers,
        showOnlyInactiveCustomers,
    } = props;

    const personalDispatch = useDispatch();

    const loadAgencyCustomerListing = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyCustomerListing'));
            let additionalParams = null;
            if(associateFilter) {
                const associateFilterId = Object.values(associateFilter).find(value => value !== null && value !== undefined)?.id || null;
                if(associateFilterId !== -1) {
                    additionalParams = {actAsAssociateId: associateFilterId};
                }
            }
            return await window.shell.gateway.getPageOfAgencyCustomers(odata, additionalParams);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyCustomerListing'));
        }
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0,
        };
        odata.filter.customerAssociateId = customerAssociateId;
        if(showOnlyActiveCustomers) odata.filter.isActive = true;
        if(showOnlyInactiveCustomers) odata.filter.isActive = false;
        if(associateFilter?.agencyCustomerOwner?.id === -1) {
            odata.filter.assignedOwnerName = "UNASSIGNED";
        }
        if(associateFilter?.agencyAssignedUser?.id === -1) {
            odata.filter.assignedUsersNames = "NONE";
        }
        return odata;
    };

    const gridColumns = [
        {
            title: 'Customer Name',
            field: 'customerName',
            type: 'custom',
            cell: CompanyLinkCell,
            sortable: true,
        },
        {title: 'Disabled', field: 'isActive', type: 'custom', sortable: true, cell: IsActiveCell},
        {field: 'lastPickupDate', title: 'Last Pickup Date',
            type: 'date',
            sortable: true,
        },
        {title: 'Assigned Owner', field: 'assignedOwnerName', type: 'custom', sortable: true, cell: AssignedOwnerCell},
        {title: 'Assigned Users', field: 'assignedUsersNames', type: 'custom', sortable: false, cell: AssignedUsersCell},
        {
            field: 'Has Visibility',
            type: 'custom',
            cell: (props) =>
                    <HasVisibilityCell
                        {...props}
                        handleHasVisibilityClick={handleHasVisibilityClick}
                    />,
            sortable: false,
        },
        {title: 'Last Note Date', field: 'latestNoteDate', type: 'date', className: "center"},
        {
            title: 'Last Note', field: 'latestNote', width: 100, type: 'custom',
            cell: (props) => <LastNoteCell {...props} />,
            sortable: false,
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) =>
                <EditAssignmentCell
                    {...props}
                    handleEditAssignmentClick={handleEditAssignmentClick}
                />,
            width: 40,
            sortable: false,
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    ref={ref}
                    onLoadDataPage={loadAgencyCustomerListing}
                    gridColumns={gridColumns}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    sort={[{field: "lastPickupDate", dir: "desc"}]}
                    skipInitialLoad={true}
                />
            </Grid>
        </Grid>
    );
});
