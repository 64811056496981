import ActionBuilder from "../../../ActionBuilder2";
import ActionTypes from "../../../ActionTypes";

const loadAssociateMonthlyCommissionBreakdown = async (shell, associateId, startDate, endDate, hideCustomerOwnerShipments = null, associateFilter = null) => {

    const associateCommissionBreakdown = await shell.gateway.getMonthlyCommissionBreakdown(
        associateId,
        startDate,
        endDate,
        hideCustomerOwnerShipments,
        associateFilter?.agencyCustomerOwner?.id
    );

    return {
        type: ActionTypes.Reporting.MonthlyGrossMargin.MonthlyCommissionBreakdownLoaded,
        associateCommissionBreakdown
    }
}

export default ActionBuilder
    .for(loadAssociateMonthlyCommissionBreakdown)
    .build();
