import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import {Assignment, FindInPage} from "@material-ui/icons";
import React from "react";
import {TgfIconTooltip} from "../../../../../core/kendo/tooltips/TgfIconTooltip/TgfIconTooltip";


export const CompanyLinkCell = (cellProps) => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const customerId = cellProps.dataItem?.customerId;
    const customerName = cellProps.dataItem?.customerName;
    return <td>
        {
            customerId ?
                <Link
                    className={"tgf-grid-link-cell"} to={`/address-book/${customerId}/details`}
                    target={openLinksInNewTab ? "_blank" : ""}
                >
                    {customerName}
                </Link>
                :
                <span style={{color: "red"}}>POSTING</span>
        }
    </td>;
};


export const IsActiveCell = (cellProps) => {
    const isActive = cellProps.dataItem?.isActive;

    return <td style={{textAlign: "center"}}>
        <span>{isActive ? 'No' : 'Yes'}</span>
    </td>;
};


export const AssignedOwnerCell = (cellProps) => {
    const { assignedOwnerName, assignedOwnerActive }  = cellProps.dataItem;
    const ownerIsInactive = (!assignedOwnerActive && assignedOwnerName !== "UNASSIGNED");

    return <td>
        <span style={{color: ownerIsInactive ? "red" : "inherit"}}>{assignedOwnerName ? assignedOwnerName : 'UNASSIGNED'}</span>
    </td>;
};


export const AssignedUsersCell = (cellProps) => {
    let { assignedUsersNames, assignedUsersIsActive} = cellProps.dataItem;
    if(assignedUsersNames !== "NONE") assignedUsersNames = assignedUsersNames.split(',');
    if(assignedUsersIsActive) assignedUsersIsActive = assignedUsersIsActive.split(',');

    return <td>
        {assignedUsersNames !== "NONE" ?
            assignedUsersNames.map((name, idx) => {
                const assignedUserIsActive = assignedUsersIsActive[idx] === '1';
                return (
                    <span style={{color: assignedUserIsActive ? "inherit" : "red"}} >{name}, </span>

                )
            })
            : <span>NONE</span>}
    </td>;
};


export const HasVisibilityCell = (props) => {
    const { handleHasVisibilityClick } = props;
    const { customerId, customerName } = props.dataItem;

    return (
        <td style={{textAlign: "center"}}>
            <IconButton
                color={'primary'}
                onClick={() => handleHasVisibilityClick(customerId, customerName)}
            >
                <FindInPage color={'primary'} fontSize={'small'} />
            </IconButton>
        </td>
    );
};

export const EditAssignmentCell = (props) => {
    const { handleEditAssignmentClick } = props;
    const { customerId, customerName } = props.dataItem;

    return (
        <td style={{textAlign: "center"}}>
            <IconButton
                color={'primary'}
                onClick={() => handleEditAssignmentClick(customerId, customerName)}
            >
                <FindInPage color={'primary'} fontSize={'small'} />
            </IconButton>
        </td>
    );
};



export const LastNoteCell = (props) => {


    let {latestNote, latestNoteDate, latestNoteAssociateFullName} = props.dataItem;
    latestNoteDate = latestNoteDate
        ? new Date(latestNoteDate).toLocaleString("en-US", {
            dateStyle: "short",
            timeStyle: "short",
        })
        : null;

    const tooltipContent = latestNote ? (
        <>
            <p>{latestNoteAssociateFullName} {latestNoteDate}</p>
            <hr/>
            <p><span style={{fontWeight: 'bold'}}>Note:</span> {latestNote}</p>
        </>
    ) : null;

    return (
        <td style={{textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <TgfIconTooltip content={tooltipContent} icon={Assignment} maxWidth={600} />
        </td>
    );
}