import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const TgfAgencyAssociatesComboBox = (props) => {
    const {
        id,
        label,
        selectedAgencyAssociate,
        onChange,
        activeOnly = false,
        visibilityPermissionIdFilter = null,
        actionRoleId = null,
        agencyId= null,
        customerOwnersOnly = false,
        includeUnassignedOption = false,
        includeNoneOption = false,
        placeholder
    } = props;
    const personalDispatch = useDispatch();
    const [agencyAssociateData, setAgencyAssociateData] = useState([]);

    const loadAgencyAssociates = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAssociate'));

            const odata = {
                top: 100,
                skip: 0,
                filter: {}
            };

            if (visibilityPermissionIdFilter) {
                const agencyAccessFilters = await window.shell.gateway.getAgencyAccessFilters();
                const agencyAccessId = agencyAccessFilters.find(
                    (item) => item.id === visibilityPermissionIdFilter,
                )?.id;
                odata.filter = {accessFilterId: agencyAccessId};
            }

            if (activeOnly) {
                odata.filter.isActive = activeOnly;
            }

            if (actionRoleId) {
                odata.filter.actionRoleId = actionRoleId;
            }

            if (customerOwnersOnly) {
                odata.filter.IsACompanyOwner = customerOwnersOnly;
            }

            if(agencyId) {
                odata.filter.agencyId = agencyId;

                const {items} = await window.shell.gateway.getPageOfAgencyAssociates(odata);

                items.forEach((associate) => {
                    associate.fullName = `${associate.firstName} ${associate.lastName}${!associate.isActive ? " (inactive)" : ""}`;
                });

                if(includeUnassignedOption) {
                    items.push({id: -1, fullName: "UNASSIGNED"});
                }
                if(includeNoneOption) {
                    items.push({id: -1, fullName: "NONE"});
                }
                setAgencyAssociateData(items);
            }
            else{
                setAgencyAssociateData([]);
            }

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAssociate'));
        }
    };

    useEffect(() => {
        if (agencyId) {
            loadAgencyAssociates();
        }
    }, [agencyId]);

    return (
        <>
            {label && <Label editorId={id}>{label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                data={agencyAssociateData}
                dataItemKey={"id"}
                textField="fullName"
                filterable={true}
                onChange={onChange}
                value={selectedAgencyAssociate}
                placeholder={placeholder ?? "Search Agency Associates..."}
                label={null}
            />
        </>
    );
};

export default TgfAgencyAssociatesComboBox;
